const timeout = (ms) =>
    new Promise((_, reject) =>
        setTimeout(() => reject(new Error("Request timed out")), ms)
    );

const fetchWithTimeout = (url, options, timeoutDuration = 10000) => {
    return Promise.race([fetch(url, options), timeout(timeoutDuration)]);
};

function handlePrelaunchClick(e) {
    e.preventDefault();

    // Get button and loadingIndicator references
    const button = document.getElementById("submitButton");

    button.disabled = true;
    button.value = "Submitting...";

    grecaptcha.ready(function () {
        grecaptcha
            .execute("6Lfi8I4nAAAAANW-BGJNCCQ3JUwFC_MDl-w2QdrT", {
                action: "submit",
            })
            .then(function (token) {
                return fetchWithTimeout(
                    "https://app.campfirechat.co/prelaunch/users",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            "X-Campfire-Recaptcha": token,
                        },
                        body: JSON.stringify({
                            phone_number: document.getElementById("phone").value,
                            interests: document.getElementById("interests").value,
                            name: document.getElementById("name").value,
                        }),
                    },
                    60000
                );
            })
            .then(function (response) {
                button.disabled = false;
                button.value = "Request →";

                if (response.status === 200) {
                    // redirect to success page
                    location.href = "/success.html";
                } else {
                    // notify user of failure
                    alert(
                        "Something went wrong submitting your pre-registration. Please try again."
                    );
                }
            })
            .catch(function (error) {
                button.disabled = false;
                button.value = "Request →";
                // notify user of failure
                alert(
                    "Something went wrong submitting your pre-registration. Please try again."
                );
            });
    });
}